import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Testing() {

  return (
    
    <div>
      <Header />
      
{/* Main Content Section */}
<div id="digitalimage">
      
        <h1>TESTING</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient">
        <b>Optimize Your Strategy with Comprehensive Digital Marketing Testing!</b>
      </p>
      <p id="stream">
        Enhance your digital marketing efforts by rigorously testing and refining your strategies to ensure 
        maximum effectiveness and better results.
      </p>
</div>
      <div id="digitalimage2">
        <img src="/images/testingnew.avif" alt="Testing Process" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Software testing is a critical process in the software development lifecycle that involves evaluating and verifying that a software application or system meets specified requirements and functions correctly. The primary goal of testing is to identify and fix bugs, ensure quality, and verify that the software behaves as expected in different scenarios.
            <br /><br />
            Testing ensures that the software meets the quality standards set by the development team and stakeholders. It helps in identifying defects early in the development process, reducing the cost and time needed for fixing issues.
            <br /><br />
            By thoroughly testing the software, potential risks and vulnerabilities can be identified and mitigated. 
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Targeted Audience Engagement</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Data-Driven Strategies</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Cost-Effective and Scalable</b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Consulting" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
</div>

        {/* Footer Section */}
      <Footer />






    </div>
  );
}




export default Testing;
