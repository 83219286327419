import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Social() {

  return (
    
    <div>
       <Header />

      <div id="digitalimage">
       
        <h1>SOCIAL MEDIA<br />MARKETING (SMM)</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient"><b>Boost Your Brand's Online Presence</b></p>
      <p id="stream">Leverage strategic social media marketing to connect with your audience, enhance brand 
        visibility, and drive engagement across all major platforms.</p>
</div>
      <div id="digitalimage2">
        <img src="/images/socialnew.avif" alt="Social Media Marketing" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>Social Media Marketing (SMM) is a powerful strategy within digital marketing that leverages social media platforms to promote products, services, or brands. It involves creating and sharing content on social media networks like Facebook, Instagram, Twitter, LinkedIn, and others to achieve marketing and branding goals.
            <br /><br />
            By creating engaging content and sharing it on social media platforms, businesses can increase their visibility and reach a wider audience, helping to establish and strengthen their brand identity.
            <br /><br />
            Social media platforms enable direct interaction with customers. Through likes, comments, shares, and messages, businesses can engage with their audience, fostering relationships and building a community around their brand.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b> &gt; Brand Awareness and Engagement </b></p>
          </div>
          <div id="target1">
            <p><b> &gt; Cost Effective Advertising </b></p>
          </div>
          <div id="target2">
            <p><b> &gt; Real-Time Interaction and Feedback </b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Social Media Strategy" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
</div>


         {/* Footer Section */}
     <Footer />

    </div>
  );
}




export default Social;
