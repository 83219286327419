import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Mobile() {

  return (
    
    <div>
     <Header />

{/* Main Content Section */}
<div id="digitalimage">
       
        <h1>MOBILE APP<br />DEVELOPMENT</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient"><b>Revolutionize Your Business with Cutting-Edge Mobile App Development</b></p>
      <p id="stream">
        Create sophisticated mobile apps that offer a seamless user experience helping you connect with 
        your audience and achieve your strategic objectives.
      </p>
</div>
      <div id="digitalimage2">
        <img src="/images/website.avif" alt="Mobile App Development Example" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Mobile app development is the process of creating software applications that run on mobile devices, such as smartphones and tablets. These applications can be designed for various operating systems, including Android, iOS, and Windows.
            <br /><br />
            Mobile app development involves not only writing code but also designing the user interface, integrating various features, and ensuring that the app functions smoothly across different devices.
            <br /><br />
            With billions of smartphone users worldwide, mobile apps have become a primary means of accessing information, services, and entertainment. Businesses and organizations increasingly rely on mobile apps to reach their audience, enhance customer engagement, and provide value-added services.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Enhanced User Experience</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Platform-Specific Optimization</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Scalable and Integrated Solutions</b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Consulting" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
      </div>


       {/* Footer Section */}
     <Footer />

    </div>
  );
}




export default Mobile;
