import React from 'react';
import './homepage.css';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Ai() {
  
  return (
    <div>
      <Header />

        {/* Main Content Section */}
      <div id="digitalimage">
      
        <h1>AI & ML</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <p id="Efficient">
        <b>Supercharge Your Business with AI & Machine Learning Solutions</b>
      </p>
      <p id="stream">
        Leverage AI and Machine Learning to automate processes, gain deeper insights, and drive smarter
        business decisions for accelerated growth.
      </p>

      <div id="digitalimage2">
        <img src="/images/ai2.avif" alt="AI & ML Process" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Artificial Intelligence (AI) and Machine Learning (ML) are two of the most transformative technologies of our time, revolutionizing industries, driving innovation, and reshaping the way we interact with the world.
            <br /><br />
            AI and ML are driving significant advancements across various industries, transforming the way we live and work. As these technologies continue to evolve, they will unlock new possibilities and solutions to complex problems. However, it is equally important to address the challenges and ethical implications to ensure that AI and ML are developed and used in ways that benefit society as a whole.
            <br /><br />
            Machine Learning is a key driver of AI, providing the tools and techniques that allow AI systems to improve their performance over time.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Automated Data Analysis & Insights</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Predictive Analytics & Forecasting</b></p>
          </div>
          <div id="target2">
            <p><b>&gt;Enhanced Automation & Efficiency</b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/ml3.avif" alt="Consulting" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
    </div>


         {/* Footer Section */}
    <Footer />





    </div>
  );
}




export default Ai;
