import React, { useState } from 'react';
import './homepage.css';
import Footer from '../component/Footer';
import Header from '../component/Header';
const Services = () => {


  return (
    <div>
    <Header />

      <div id="digitalimage">
        <h1>OUR SERVICES</h1>
        
       
      </div>
      <div id="work-together">
        
        <div className="work-headline-content">
          <h2>Let's work together</h2>
          <h1>We Ensure Only Best Consulting Services For Our Clients</h1>
        </div>
        <div className="work-paragraph-content">
          <p>In today's fast-paced digital world, a strong online presence is essential
            for success. At Presume Digitech, we specialize in delivering innovative
            and results-driven digital marketing strategies designed to elevate your brand and
            drive growth.
          </p>
        </div>
      </div>

      {/* Our Services Section */}
      <div id="ourservices-text">
        <h3>Our Services</h3>
      </div>
      <div className="marquee-container">
        <div className="marquee">
          <div className="box">
            <h3>1. Digital Marketing</h3>
            <p>Improve your website's visibility on search engines.</p>
            <img src='/images/digitalnew.jpeg' alt="Digital Marketing" className="bottom-image" />
          </div>
          <div className="box">
            <h3>2. Search Engine Optimization (SEO)</h3>
            <p>Improve your website's visibility on search engines and attract
              high-quality traffic with our tailored SEO strategies.
            </p>
            <img src='/images/serachnew.avif' alt="SEO" className="bottom-image" />
          </div>
          <div className="box">
            <h3>3. Pay-Per-Click Advertising (PPC)</h3>
            <p>We focus on precise targeting, ad copy optimization, and
              continuous performance monitoring.
            </p>
            <img src='/images/PAYPERCLICK.avif' alt="PPC" className="bottom-image" />
          </div>
          <div className="box">
            <h3>4. Social Media Marketing</h3>
            <p>Engage and grow your audience with strategic social media marketing.</p>
            <img src='/images/socialnew.avif' alt="Social Media Marketing" className="bottom-image" />
          </div>
          <div className="box">
            <h3>5. Website Development</h3>
            <p>Our comprehensive website development services are tailored to create an online presence
              that not only attracts visitors but also converts them into loyal customers.</p>
            <img src='/images/website5.avif' alt="Website Development" className="bottom-image" />
          </div>
          <div className="box">
            <h3>6. Mobile App Development</h3>
            <p>We specialize in creating innovative mobile apps that not only enhance user engagement
              but also drive business growth and brand loyalty.
            </p>
            <img src='/images/website.avif' alt="Mobile App Development" className="bottom-image" />
          </div>
          <div className="box"> 
            <h3>7. Testing</h3>
            <p>We emphasize a rigorous testing approach to refine our strategies, enhance user experiences,
              and achieve superior results.</p>
            <img src='/images/testingnew.avif' alt="Testing" className="bottom-image" />
          </div>
          <div className="box">
            <h3>8. AI and ML</h3>
            <p>We leverage cutting-edge AI and ML technologies to supercharge your marketing strategies,
              deliver personalized experiences, and drive measurable results.</p>
            <img src='/images/ai2.avif' alt="AI and ML" className="bottom-image" />
          </div>
          <div className="box">
            <h3>9. Cyber Security</h3>
            <p>We understand that safeguarding your digital assets and maintaining data integrity are paramount to your business success and reputation.</p>
            <img src='/images/cybernew.avif' alt="Cyber Security" className="bottom-image" />
          </div>
        </div>
      </div>

      {/* Get Started Today Section */}
      <div id="getstarted-today">
        <div className="getstarted-left">
          <img src='/images/servicesnew2.avif' alt="Get Started Today" />
        </div>
        <div className="getstarted-right">
          <h1>Business Solutions made<br />with purpose</h1>
          <p>Strategic business solutions crafted with<br />intentionally for meaningful impact.</p>
          <button>Get Started Today</button>
        </div>
      </div>


      {/* Footer Section */}
    <Footer />


    </div>
  );
}




export default Services;
