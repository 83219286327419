import React from 'react';
import './homepage.css';
import Header from '../component/Header';
import Footer from '../component/Footer';

function Contact() {
  return (
    <>
    <Header />
    <div class="contact_main">
      <h1 class="contact_h1">Contact Us</h1>
      <div>
        <section class="contact_section1">
          <h2>Get in Touch</h2>
          <p className='contact_para'>Feel free to contact us for any questions or inquiries:</p>

            <form action="https://api.web3forms.com/submit" method="POST">

              <input type="hidden" name="access_key" value="2f30f9e9-267b-4038-897f-690e4a2be047" />


            <label for="name" >Name:</label>
            <input type="text" id="name" name="name" class="contact_input" required />

            <label for="email" >Email:</label>
            <input type="email" id="email" name="email" class="contact_input" required />

            <label for="message">Message:</label>
            <textarea id="message" name="message" class="contact_input" required></textarea>
            <br />
              <input type="hidden" name="redirect" value="https://web3forms.com/success" />

              <button type='submit' className='contact_input' id='contact_submit'>Send</button>


          </form>
        </section>

        <section class="contact_section2">
          <h2>Our Location</h2>
          <p><b>We are located at:</b></p>

          <address>
            410, 4th Floor, Apollo Premier,<br /> Vijay Nagar, Indore, Madhya Pradesh
          </address>

          <p><b>Call us at:</b> <br /><a href="tel:7000625872" className='contact_links'>+91 8959575773</a></p><br />
          <p><b>Mail us at:</b> <br /><a href="mailto:info@presumedigitech.com" className='contact_links'>info@presumedigitech.com</a></p><br />

        </section>
      </div>

      <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.403807158342!2d75.89371302534958!3d22.750390829365433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39630390e137d0d7%3A0x5bef82fe270e4409!2sApollo%20Premier!5e0!3m2!1sen!2sin!4v1727160821781!5m2!1sen!2sin" width="300" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

    </div>
    <Footer />

    </>
  );
}

export default Contact;