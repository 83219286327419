import React, { useState, useEffect } from 'react';
import './Portfolio.css'; // Add your CSS styles here
import { Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState('everything');

  // Handles opening the specific projects section
  const openProjects = (sectionId) => {
    setActiveTab(sectionId);
  };

  // Set the default active tab when the component loads
  useEffect(() => {
    setActiveTab('everything');
  }, []);

  return (
    <div>

      <Header />

      {/* Main Image */}
      <div id="digitalimage">

        <h1>Projects</h1>
      </div>

      {/* Headline */}
      <div id="work-presume-headline">
        <h2>Presume Digitech<br/> Work</h2>
      </div>

      {/* Project Buttons */}
      <div className="project-buttons">
        <div className="portfolio-buttons">
          <button className={`tab-button ${activeTab === 'everything' ? 'active' : ''}`} onClick={() => openProjects('everything')}>Everything</button>
          <button className={`tab-button ${activeTab === 'commercial' ? 'active' : ''}`} onClick={() => openProjects('commercial')}>Commercial</button>
          <button className={`tab-button ${activeTab === 'graphics' ? 'active' : ''}`} onClick={() => openProjects('graphics')}>Graphics</button>
        </div>
      </div>

      {/* Everything Tab Content */}
      <div id="everything" className="portfolio-content" style={{ display: activeTab === 'everything' ? 'block' : 'none' }}>
        <div id="portfolio-project-boxes">
          <div>
            <div className="portfolio-box"><img src="images/Spring Remix Party_RGB.avif" alt="Spring Remix Party" /></div>
            <div className="portfolio-box"><img src="images/seple.avif" alt="Sep Image" /></div>
            <div className="portfolio-box"><img src="images/gym 2.avif" alt="Gym Image" /></div>

            <div className="portfolio-box"><img src="images/poster dental.avif" alt="Dental Poster" /></div>
            <div className="portfolio-box"><img src="images/happy wedding (2).avif" alt="Happy Wedding" /></div>
            <div className="portfolio-box"><img src="images/Spring Party_RGB.avif" alt="Spring Party" /></div>
          </div>

          <div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/j7zlbVHqNTM?si=0Ff4irzYoEb4iujm"
                title="YouTube Video 1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/kYDTvacrL5w?si=CAvym8klks7BCyyv"
                title="YouTube Video 2"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/WF3TieaMRmo?si=DoP7gls6xUAqsP6c"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>

        </div>
      </div>

      {/* Commercial Tab Content */}
      <div id="commercial" className="portfolio-content" style={{ display: activeTab === 'commercial' ? 'block' : 'none' }}>
        <div id="portfolio-project-boxes">
          <div>
            <div className="portfolio-box"><img src="images/poster dental.avif" alt="Dental Poster" /></div>
            <div className="portfolio-box"><img src="images/seple.avif" alt="Sep Image" /></div>
            <div className="portfolio-box"><img src="images/gym 2.avif" alt="Gym Image" /></div>
            <div className="portfolio-box"><img src="images/engine1.avif" alt="Engine" /></div>
            <div className="portfolio-box"><img src="images/Suco4.avif" alt="Suco" /></div>
            <div className="portfolio-box"><img src="images/banner full energy.avif" alt="Full Energy" /></div>
          </div>

          <div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/aH7RgH2xi_w?si=c-TpjlpPX61wOpy8"
                title="YouTube Video 3"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/Bry45IX2pnU?si=ULydeXvLsyA0D5B9"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/BMfChn3L1wA?si=Zf2kaP2TD"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/xTlLiBRqkyc?si=ef8_DBipypT9Kpvu"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/-ZZuB0MSBbE?si=eibRX0QTzwSAnvmg"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <div className="portfolio-box">
              <iframe
                src="https://youtube.com/embed/Q8oCzyZpJq8?si=lXExz7T4foGUfOyR"
                title="YouTube Shorts player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>

        </div>
      </div>

      {/* Graphics Tab Content */}
      <div id="graphics" className="portfolio-content" style={{ display: activeTab === 'graphics' ? 'block' : 'none' }}>
        <div id="portfolio-project-boxes">
          <div>
            <div className="portfolio-box"><img src="images/Spring Remix Party_RGB.avif" alt="Spring Remix Party" /></div>
            <div className="portfolio-box"><img src="images/Spring Party_RGB.avif" alt="Spring Party" /></div>
            <div className="portfolio-box"><img src="images/Suco4.avif" alt="Suco" /></div>
          </div>

          <div className='portfolio-iframe-container'>
             <div className="portfolio-box">
            <iframe
              src="https://youtube.com/embed/aH7RgH2xi_w?si=rVm_LH_CizFTML5M"
              title="YouTube Video 4"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
          <div className="portfolio-box">
            <iframe
              src="https://youtube.com/embed/NvdrdGlaTVU?si=8r1F3-6KVF-jmLka"
              title="YouTube Shorts player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
          <div className="portfolio-box">
            <iframe
              src="https://youtube.com/embed/LJCxYNowOF0?si=D7lXDj5upfY-ZJ0w"
              title="YouTube Shorts player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
          </div>
         
        </div>
      </div>

      {/* Footer */}
      <Footer />

    </div>
  );
};

export default Portfolio;
