import React from 'react';
import './homepage.css';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Pay() {

  return (
    
    <div>
     {/* Header Section */}
      <Header />

      {/* Main Content Section */}
      <div id="digitalimage">

        <h1>
          <br />PAY-PER-CLICK
          <br />ADVERTISING (PPC)
        </h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient"><b>Drive targeted traffic to your website</b></p>
      <p id="stream">
        PPC (Pay-Per-Click) is a digital advertising strategy where you pay only when your ad is clicked, driving 
        targeted traffic to your website.
      </p></div>

      <div id="digitalimage2">
        <img src="/images/PAYPERCLICK.avif" alt="PPC" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Pay-Per-Click (PPC) advertising is a digital marketing model where advertisers pay a fee each time one of their ads is clicked. Essentially, it’s a way of buying visits to your site rather than attempting to earn them organically. PPC is widely used across various online platforms, with Google Ads being the most popular.
           <br/>
            In a typical PPC campaign, advertisers create ads and bid on specific keywords relevant to their target audience. When users search for those keywords, the ads appear on the search engine results page (SERP), usually at the top or bottom. If a user clicks on the ad, the advertiser is charged a certain amount of money, hence the term "pay-per-click."
           
            The amount you pay for each click in a PPC campaign (known as cost-per-click or CPC) can vary based on several factors, including the competitiveness of the keywords and the quality and relevance of your ad.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Immediate Traffic and Results</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Budget Control and Flexibility</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Targeted Reach and Measurable ROI</b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Consulting" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
      </div>

        {/* Footer Section */}
        <Footer />

    </div>
  );
}




export default Pay;
