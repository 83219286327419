import React, { useState } from 'react';
import './homepage.css';
import { Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item" onClick={toggleFAQ}>
      <div className="faq-question" style={{ color: isOpen ? '#e74c3c' : '#f39c12' }}>
        <h3>{question}</h3>
        <span className="faq-toggle">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="faq-answer" style={{ display: 'block' }}>
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: 'What services do you offer?',
      answer: 'We offer a wide range of IT solutions including software development, Digital Marketing, SEO, Social Media marketing, Pay-Per-Click advertising, Website Development, Mobile App Development, Testing, AI & ML, Cybersecurity Solutions. Our goal is to provide tailored solutions to meet the specific needs of your business.',
    },
    {
      question: 'What is your company all about?',
      answer: 'Our company is dedicated to providing comprehensive IT solutions that drive innovation and efficiency for businesses of all sizes. We specialize in Digital Marketing, SEO, Social Media marketing, Pay-Per-Click advertising, Website Development, Mobile App Development, Testing, AI & ML, Cybersecurity Solutions. With a team of experienced professionals, we strive to deliver high-quality services that enhance your operational performance and support your long-term goals.',
    },
    {
      question: 'Where are you located?',
      answer: 'Our headquarters are situated at [410, Apollo Premier, Vijay Nagar, Indore 452010]. We also have regional offices and support centers in various locations. For detailed directions or to schedule a visit, please contact us via phone at +91-7000625872. More information, including maps and office hours, can be found on our "Contact Us" page.',
    },
    {
      question: 'What are your support hours?',
      answer: 'Our dedicated support team is available from Monday to Friday, 9:00 AM to 7:00 PM. For urgent matters, we offer 24/7 emergency support to ensure critical issues are promptly addressed. Please reach out via email at info@presumedigitech.com, by phone at (+91) 70006 25872, or through our online chat service.',
    },
    {
      question: 'Do you offer on-site support or training?',
      answer: 'Yes, we offer both on-site support and training to ensure your team can effectively utilize our solutions. Our experienced professionals are available to visit your location for hands-on support and personalized training sessions. Contact us to schedule an appointment or discuss your needs via email at info@digitech.com or by phone at (+91) 70006 25872.',
    },
    {
      question: 'Can I get a quote for my specific requirements?',
      answer: 'Absolutely! We provide customized quotes based on your specific requirements. Share the details of your project with us, and our team will prepare a tailored proposal. Request a quote by filling out the form on our "Get a Quote" page, emailing info@digitech.com, or calling us at (+91) 70006 25872.',
    },
    {
      question: 'What is included in your standard service level agreements?',
      answer: 'Our standard SLAs include: Guaranteed Uptime (99.9%), Prompt response times, Support Availability during business hours, Regular Maintenance, Performance Monitoring, and Incident Management. Contact us for more details or to discuss a customized SLA.',
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

const App = () => {
  
  return (
  <>
    <Header />

    {/* Main Image and Content */}
    <div id="portfolio-main">
      <img src="images/faq-concept.avif" alt="" className="image-left" />
      <img src="/images/FAQ.avif" alt="" className="image-right" />
      <p>
        Our digital marketing agency offers a range of services, including SEO, PPC, social media management, content marketing, and website design. We customize strategies based on your business goals and industry, providing measurable results through KPIs like traffic growth and ROI. We work across various industries and can collaborate with your existing team. Pricing is tailored to each project, and while timelines for results vary, we prioritize transparency and regular updates.
      </p>
      <button>Get Started Today</button>
    </div>

    {/* FAQ Section */}
    <FAQ />

    {/* Footer Section */}
    <Footer />

  </>
  );
};

export default App;
