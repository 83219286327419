import React from 'react';
import './homepage.css';
import Footer from '../component/Footer';
import Header from '../component/Header';

function About() {
  
  return (
    <div>
      <Header />

      {/* Main Image Section */}
      <div id="aboutmainimage">
        <video autoPlay muted loop id="background-video">
          <source src="/images/intro (1) (1).mp4" type="video/mp4" />
          Your browser can't play this video.
        </video>
      </div>

      {/* About Brief Section */}
      <div id="aboutbrief">
        <div className="aboutuscontent">
          <h2>About Us</h2>
          <p>
            Presume Digitech, A digital marketing agency where we help you to advertise your company in the digital market while giving the public a long-lasting impression of your company. A well conveived digital marketing strategy can produce remarkable results.
            
          </p>
        </div>
        <div className="aboutus-rightsideimage">
          <img src="/images/aboutnew.avif" alt="3D Girl" />
        </div>
      </div>


      {/* Packages Section */}
      <div id="packages-container">
        <div className="image-row">
          <div className="image-container">
            <div className='mission'>
              <h2>Mission</h2>
              <div className='image3'>
                <img src='/images/misssion icon.avif'/>
              </div>
            </div>


            <p className="description desc-1">
              <b>Mission </b>
              <hr />

              <br />We strive to be the catalyst for our clients’ success in the digital realm by providing innovative strategies, advanced technologies, and unparalleled support in digital marketing and information technology realm.</p>
          </div>
          <div className="image-container">
          <div className='mission'>
              <h2>Vission</h2>
              <div className='image3'>
                <img src='/images/vissionnew.avif'/>
              </div>
            </div>
            <p className="description desc-2">
              <b>Vission </b>
              <hr />

              <br />
              Our goal is to ensure that every business we work with not only survives but thrives in the ever-evolving digital landscape.</p>
          </div>
          <div className="image-container">
          <div className='mission'>
              <h2>Strategy</h2>
              <div className='image3'>
                <img src='/images/strategynew.avif'/>
              </div>
            </div>
            <p className="description desc-3">
              <b>Strategy </b>
              <hr />

              <br />
              We at Presume leverage and strongly believe in:<br />Cutting Edge technology<br />• Customized solutions<br />• Results-Driven approach</p>
          </div>
        </div>

      </div>
      <h2 id='why'>Why Choose Us?</h2>
      <div className='about_row'>
        <div className='about_column'>
          <div id='about1'><p>Proven Results</p></div>
          <div id='about1'><p>Customized Solutions</p></div>
        </div>
        <div className='about_column'>
          <div id='about1'><p>Client-Centric Focus</p></div>
          <div id='about1'><p>Experienced Team</p></div>
        </div>
      </div>


      <div id='about5'>
        <p>We believe in a transparent, results-driven approach. We work closely with our clients, understanding their needs and providing data-backed strategies that deliver measurable success. With regular reporting and open communication, we ensure you’re always informed and confident in our partnership.</p>
      </div>

      <div className='about_row'>
        <div className="aboutus-rightsideimage2">
          <img src="/images/aboutnew2.avif" alt="3D Girl" />
        </div>
        <div id='aboutpara'>
          At Presume Digitech, we're not just about marketing we're about building lasting relationships and driving success. Ready to take your digital presence to the next level? Let's work together to turn your vision into reality.
        </div>
      </div>


      {/* Footer Section */}
   
<Footer />




    </div>
  );
}

export default About;
