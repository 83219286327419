import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Privacy() {

  return (
    
    <div>
       {/* Header Section */}
       <Header />

      <div id="digitalimage">

        <h1>
          Privacy and Policy
        </h1>
      </div>

<h1 id='privacy1'></h1>
<p id='privacy2'>PRESUME DIGITECH is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.presumedigitech.com], use our services, or engage with our content.</p>

<h2 id='privacy3'>1. Information We Collect</h2>
<p id='privacy4'>
We may collect and process the following data:
</p>
<p id='privacy5'>
    <ul>
        <li>Personal Information: Name, email address, phone number, company name, job title, and other contact details.</li>
    <li>Usage Data: Information on how you interact with our website, including your IP address, browser type, pages visited, and the duration of your visit.</li>
    <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your experience on our website, track user activity, and gather analytics.</li>
    </ul>
</p>
<h2 id='privacy3'>2. How We Use Your Information
</h2>
<p id='privacy4'>
We use the information we collect for the following purposes:
</p>
<p id='privacy5'>
    <ul>
        <li>To Provide and Improve Our Services: To deliver the services you request, enhance your experience, and personalize content.</li>
    <li>Marketing Communications: To send you promotional materials, updates, and offers that may be of interest to you, with your consent where required by law.</li>
    <li>Analytics and Research: To analyze usage trends, track website performance, and conduct market research to improve our offerings.</li>
   <li>Compliance with Legal Obligations: To comply with applicable laws, regulations, and legal processes.
</li>
    </ul>
</p>
<h2 id='privacy3'>3. Sharing Your Information</h2>
<p id='privacy4'>
We do not sell or rent your personal information to third parties. However, we may share your data with:
</p>
<p id='privacy5'>
    <ul>
        <li>Service Providers: Third-party vendors who assist in delivering our services, such as hosting providers, email marketing platforms, and analytics services.</li>
    <li>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
    <li>Legal Requirements: If required by law or in response to valid legal processes, we may disclose your information to comply with legal obligations.</li>
    </ul>
</p>
<h2 id='privacy3'>4. Data Security
</h2>
<p id='privacy4'>
We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.

</p>

<h2 id='privacy3'>5. Your Rights</h2>
<p id='privacy4'>
Depending on your location and applicable laws, you may have the following rights concerning your data:
</p>
<p id='privacy5'>
    <ul>
        <li>Access: Request access to the personal data we hold about you.</li>
    <li>Rectification: Request correction of inaccurate or incomplete data.</li>
    <li>Erasure: Request deletion of your personal data under certain circumstances.</li>
    <li>Objection: Object to our processing of your personal data.</li>
    <li>Data Portability: Request to receive your data in a structured, commonly used format</li>
    </ul>
</p>
<h2 id='privacy3'>6. Cookies and Tracking Technologies</h2>
<p id='privacy4'>
We use cookies and other tracking technologies to improve your experience on our website and gather data for analytics. You can manage your cookie preferences through your browser settings. For more details, please refer to our [Cookie Policy].
</p>

<h2 id='privacy3'>7. Third-Party Links</h2>
<p id='privacy4'>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review their privacy policies before providing any personal information.

</p>

<h2 id='privacy3'>8. Changes to This Privacy Policy</h2>
<p id='privacy4'>
We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we protect your information.

</p>

<h2 id='privacy3'>9. Contact Us
</h2>
<p id='privacy4'>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br/>
PRESUME DIGITECH<br/> 410, Apollo Premier, Vijay Nagar, Indore, Madhya Pradesh 452011<br/> presumedigitech@gmail.com<br/> +91 8959575773
</p>











{/* Footer Section */}
<Footer />




    </div>
  );
}

export default Privacy;
