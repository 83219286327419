import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Website() {

  return (
    
    <div>
       <Header />


      {/* Main Content Section */}
      <div id="digitalimage">
       
        <h1>WEBSITE DEVELOPMENT</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient"><b>Elevate Your Brand with Cutting-Edge Website Development Solutions</b></p>
      <p id="stream">
        Develop a visually striking and highly functional website tailored to your business needs, enhancing 
        user experience and driving growth.
      </p>
</div>
      <div id="digitalimage2">
        <img src="/images/website5.avif" alt="Website Development Example" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Website development is the process of creating and maintaining websites. It involves a wide range of tasks, from designing the layout and user interface to coding the functionality and integrating various features that make a website interactive and functional. Website development is a crucial aspect of establishing an online presence, whether for businesses, organizations, or individuals.
            <br /><br />
            Full-stack developers are proficient in both front-end and back-end development. They can build a complete website from the ground up, handling everything from the user interface to the server-side logic.
            <br /><br />
            Responsive design ensures that a website looks and functions well on all devices, including desktops, tablets, and smartphones. </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; User-Centric Design & Functionality</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; SEO & Performance Optimization</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Scalability and Flexibility</b></p>
          </div>
        </div>
      </div>
      
      <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Consulting" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
    </div>


       {/* Footer Section */}
      <Footer />




    </div>
  );
}




export default Website;
