import React, { useState } from 'react';
import './homepage.css';
import { Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Cyber() {

  return(
    <>
      <Header />
      {/* Main Content Section */}
      <div id="digitalimage">
        
        <h1>CYBER SECURITY</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
        <p id="Efficient"><b>Protect Your Business with Advanced Cybersecurity Solutions</b></p>
        <p id="stream">
          Safeguard your digital assets with cutting-edge cybersecurity solutions that defend against threats
          and ensure your business's data integrity and security.
        </p>
      </div>
      <div id="digitalimage2">
        <img src="/images/cybernew.avif" alt="Cybersecurity Process" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>
            Cybersecurity is the practice of protecting systems, networks, and data from digital attacks, unauthorized access, and other forms of cyber threats. As our world becomes increasingly connected through technology, the importance of cybersecurity has grown exponentially. Cybersecurity encompasses a range of strategies, tools, and practices designed to safeguard the confidentiality, integrity, and availability of information.
            <br /><br />
            Cyber threats can come in various forms, including malware, ransomware, phishing, and hacking. These threats can target individuals, businesses, governments, and critical infrastructure, causing significant financial loss, data breaches, and operational disruptions.
            <br /><br />
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Comprehensive Threat Protection</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Data-Integrity and Compliance</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Incident Response and Recovery</b></p>
          </div>
        </div>
      </div>


      <div className='service_last'>
        <div id="digitalimage3">
          <img src="images/cyber3.avif" alt="Consulting" />
        </div>
        <div id="digital3">
          <h3>Our consultant is always in sync with your strategy</h3>
        </div>
      </div>

      {/* Footer Section */}
      <Footer />
  </>
  );
}




export default Cyber;
