import React, { useState } from 'react';
import './homepage.css';
import {Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Digital() {
  
  return (
    <>
    <Header />

        {/* Digital Marketing Image Section */}
        <div id="digitalimage">
       
        <h1>DIGITAL
        <br/> MARKETING</h1>
      </div>

      {/* Services Section */}
      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
        <p id="Efficient"><b>Efficient and effective day-to-day management of funds</b></p>
      <p id="stream">Streamline digital marketing budgets with precise tracking, strategic forecasting, and optimized cash
        flow management for maximum ROI.</p> 
      </div>
     

      <div id="digitalimage2">
        <img src="/images/digitalnew.jpeg" alt="Digital Marketing Service" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>We offer a comprehensive suite of digital marketing services designed to elevate your brand. From social media marketing to graphic design, video editing, animation, 3D modeling, and logo design, we specialize in crafting compelling content that resonates with your audience.
            <br />
            
            Our team of skilled professionals utilizes industry-leading tools to deliver exceptional results. Whether you need captivating visuals, engaging videos, or immersive 3D experiences, we have the expertise and creativity to bring your ideas to life.
           <br/>
            We understand that every business is unique, which is why we tailor our services to meet your specific needs and goals. Whether you’re looking to boost your social media presence, enhance your brand identity, or create stunning visual content, we’re here to help you succeed in the digital landscape.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b> &gt; Targeted Audience Engagement </b></p>
          </div>
          <div id="target1">
            <p><b> &gt; Data-Driven Strategies </b></p>
          </div>
          <div id="target2">
            <p><b> &gt; Cost-Effective and Scalable </b></p>
          </div>
        </div>
      </div>

    <div className='service_last'>
      <div id="digitalimage3">
        <img src="images/digital marketingpage3.avif" alt="Digital Marketing Strategy" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
    </div>
      



  {/* Footer Section */}
 <Footer />






    </>
  );
}




export default Digital;
