import React, { useState } from 'react';
import './homepage.css';
import { Link } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';

function Search() {

  return (
    <div>
      
    <Header />

      {/* Main Content Section */}
      <div id="digitalimage">
      
        <h1>SEARCH Engine<br />OPTIMIZATION (SEO)</h1>
      </div>

      <h2 id="digitalservices">Our Services</h2>
      <div className='service_intro'>
      <p id="Efficient"><b>Enhancing a website's visibility on search engines</b></p>
      <p id="stream">We enhance our client's online presence through expert SEO, optimizing website content, technical 
        elements, and backlink profiles to boost search enginerankings and drive organic traffic.</p>
</div>
      <div id="digitalimage2">
        <img src="/images/serachnew.avif" alt="SEO Service" />
      </div>

      <div id="digitalcontent">
        <div id="digitalcontent1">
          <p>Search Engine Optimization (SEO) is the practice of enhancing a website's visibility and ranking on search engine results pages (SERPs) through various techniques and strategies. The primary goal of SEO is to attract more organic traffic by ensuring that a website appears prominently when users search for relevant keywords or phrases.
            <br /><br />
            This involves optimizing various elements of a website, including its content, structure, and technical aspects, to improve its relevance and authority in the eyes of search engines like Google, Bing, and Yahoo.
            <br /><br />
            Effective SEO not only increases the quantity of traffic but also its quality, leading to better engagement and conversion rates.
          </p>
        </div>
        <div id="digitalcontent2">
          <div id="target">
            <p><b>&gt; Improved Visibility and Rankings</b></p>
          </div>
          <div id="target1">
            <p><b>&gt; Enhanced User Experience</b></p>
          </div>
          <div id="target2">
            <p><b>&gt; Long-Term Results and Credibility</b></p>
          </div>
        </div>
      </div>

      <div className='service_last'>
      <div id="digitalimage3">
        <img src="/images/search2.avif" alt="SEO Strategy" />
      </div>
      <div id="digital3">
        <h3>Our consultant is always in sync with your strategy</h3>
      </div>
</div>


       {/* Footer Section */}
      <Footer />

    </div>
  );
}

export default Search;
